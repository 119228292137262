/* stylelint-disable selector-class-pattern */
/* stylelint-disable font-family-no-missing-generic-family-keyword */
@charset "utf-8";

/* CSS Document */

/* ----------------------------------------------------------------------- */

/* #BASE */

/* root */
:root {
    /* colores */
    --color-verde: #509f2c;
    --color-verde-rgba-1: rgb(178 207 103 / 47%);
    --color-verde-rgba-2: rgb(219 232 183 / 29%);
    --color-verde-rgba-3: rgba(80, 159, 44, 0.6);
    --color-rojo: #ff3030;
    --color-gris: #6c6b6c;
    --color-000: #000;
    --color-fff: #fff;
    --color-f5f: #f5f5f5;
    --color-ddd: #ddd;
    --color-eee: #eee;
    --color-ccc: #ccc;
    --color-999: #999;
    --color-666: #666;
    --color-333: #333;
    --color-placeholder: var(--color-999);

    /* font */
    --font: 'Fira Sans', sans-serif;

    /* medidas */
    --w-filtro: 400px;
    --w-carpetas: 270px;
    --h-footer: 155px;

    /* height */
    --h-header: 100px;
    --h-header-scroll: 50px;
    --h-buscador: 150px;
    --h-buscador-scroll: 70px;
    --top-main: var(--h-header);
    --top-main-buscador: calc(var(--h-header) + var(--h-buscador));

    /* radius */
    --radius-8: 8px;
    --radius-12: 12px;
}

*,
::before,
::after {
    margin: 0;
    box-sizing: border-box;
    position: relative;
    font-weight: normal;
    font-style: normal;
}

/* selection */
::selection {
    background: var(--color-verde);
    color: var(--color-fff);
}

::-o-selection {
    background: var(--color-verde);
    color: var(--color-fff);
}

::-ms-selection {
    background: var(--color-verde);
    color: var(--color-fff);
}

/* row */
.cyg-row {
    width: 100%;
    padding: 0 7px;
}

.cyg-row-height [class*='col-'] {
    float: left;
}

.cyg-row-reverse {
    flex-direction: row-reverse;
}

/* col's custom */
[class*='cyg-col-'] {
    float: left;
}

.cyg-col-left {
    width: 63%;
}

.cyg-col-right {
    width: 37%;
}

.cyg-col-full {
    width: 100%;
}

/* margin */
.mb-0 {
    margin-bottom: 0 !important;
}

/* padding */
.pl-15 {
    padding-left: 14px;
}

.pr-15 {
    padding-right: 14px;
}

.pr-30 {
    padding-right: 28px;
}

.pl-30 {
    padding-left: 28px;
}

/* display */
.d-flex {
    display: flex;
}

.d-table {
    display: table;
}

/* align items */
.ai-center {
    align-items: center;
}

/* justify content */
.j-flex-end {
    justify-content: flex-end;
}

.j-space-between {
    justify-content: space-between;
}

main,
header,
section,
form,
aside,
nav,
time,
picture,
figure,
article,
address,
footer {
    width: 100%;
    float: left;
}

/* ratio */
[class*='ratio'] {
    width: 100%;
    float: left;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratio-100 {
    padding-top: 100% !important;
}

.ratio-75 {
    padding-top: 75% !important;
    z-index: 1;
}

.ratio-56 {
    padding-top: 56.25% !important;
}

.ratio-37 {
    padding-top: 37.34% !important;
}

/* #img */
img {
    width: auto;
    max-width: 100%;
    height: auto;
}

figure {
    margin: 0;
    line-height: 0;
}

figcaption {
    width: 100%;
    float: left;
}

[class*='ratio'] img {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: inherit;
    margin: auto;
    object-fit: cover;
}

/* #bg */
.bg-verde {
    background-color: var(--color-verde);
}

.bg-verde-rgba-1 {
    background-color: var(--color-verde-rgba-1);
}

.bg-verde-rgba-2 {
    background-color: var(--color-verde-rgba-2);
}

.bg-warning {
    background-color: #FFFF007F;
}

.bg-error {
    background-color: #FF00007F;
}

.bg-gris {
    background-color: var(--color-gris);
}

.bg-negro {
    background-color: var(--color-000);
}

.bg-blanco {
    background-color: var(--color-fff);
}

.bg-textura::before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url('../images/bg-textura.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    mix-blend-mode: multiply;
}

.bg-degrade {
    background: rgb(17 17 17);
    background: linear-gradient(0deg, rgb(17 17 17 / 100%) 5%, rgb(17 17 17 / 0%) 100%);
    background: linear-gradient(0deg, rgb(17 17 17 / 100%) 5%, rgb(17 17 17 / 0%) 100%);
    background: linear-gradient(0deg, rgb(17 17 17 / 100%) 5%, rgb(17 17 17 / 0%) 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    opacity: 0.95;
}

/* #height */
.full-height {
    overflow: hidden;
    height: 100vh;
}

/* #scrollbar */
::-webkit-scrollbar {
    width: 9px;
}

#filtros ::-webkit-scrollbar {
    width: 5px !important;
    position: absolute !important;
    top: 0;
    right: 0;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--color-ddd);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color-verde);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* #preload */
#preload {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999999999999999999;
    background-color: var(--color-eee);
    display: flex;
    align-items: center;
    justify-content: center;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid var(--color-verde);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

#preload video {
    width: 320px !important;
    height: 150px !important;
    margin: auto !important;
    right: 0;
    bottom: 0;
    transform: translateY(-60px);
}

.d-none {
    display: none !important;
}

/* ----------------------------------------------------------------------- */

/* #BODY */
html {
    min-height: 100%;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    font-size: 1rem;
    background-color: var(--color-fff);
    color: var(--color-gris);
    font-family: var(--font);
    font-weight: 400;
    min-height: 100%;
}

/* ----------------------------------------------------------------------- */

/* #CONT - CONTAINER */
#root {
    width: 100%;
    min-height: 100%;
    min-height: 100vh;
    overflow: hidden;

    /* padding-bottom: var(--h-footer); */
}

/* container */
.container {
    width: 100%;
    max-width: 1333px;
    padding: 0 28px;
    margin: auto;
}

.container-2 {
    max-width: 1495px;
}

.container-3 {
    max-width: 1333px;
}

.container-full {
    max-width: 100%;
    padding: 0 !important;
}

.not-logged {
    padding-top: 50px;
}

.not-logged-title {
    padding-bottom: 30px;
    color: var(--color-verde);
    ;
}

.not-logged-subtitle {
    font-size: large;
    padding-bottom: 10px;
}

.faq {
    padding-top: 25px;
}

.faq h2 {
    font-size: 1.75rem;
    padding-bottom: 20px;
    color: var(--color-verde);
    font-weight: 600;
}

.faq h1 {
    font-size: 1.5rem;
    padding-top: 20px;
    padding-bottom: 5px;
    color: var(--color-verde);
    ;
}

.faq div {
    font-size: 1.5rem;
    color: var(--color-verde);
    ;
}

.faq p {
    font-size: medium;
    color: black;
}

.faq a {
    font-size: medium;
    color: var(--color-verde);
    ;
}

.faq img {
    height: 720px;
}

.row2 {
    display: flex;
    overflow-x: auto;
}

.column {
    flex: 50%;
    padding-right: 25px;
}

.main-title .Collapsible__trigger {
    color: var(--color-verde);
    background: var(--color-verde-rgba-1);
}

.Collapsible__contentInner {
    padding: 10px;
    border: 1px solid lightGrey;
    border-top: 0;
}

.Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    color: white;
    background: var(--color-verde);
    margin-top: 10px;
}

/* ----------------------------------------------------------------------- */

/* #TEXTO's */
h1 {
    font-size: 2.4rem;
}

h2 {
    font-size: 2.05rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.35rem;
}

h5 {
    font-size: 1.2rem;
}

h6 {
    font-size: 1rem;
}

/* #color */
.tx-verde,
.tx-verde * {
    color: var(--color-verde);
}

.tx-gris,
.tx-gris * {
    color: var(--color-gris);
}

.tx-white,
.tx-white * {
    color: var(--color-fff);
}

/* #transform */
.uppercase {
    text-transform: uppercase !important;
}

/* #weight */
.normal,
.normal * {
    font-weight: 400 !important;
}

.semi-bold,
.semi-bold * {
    font-weight: 600 !important;
}

.bold,
.bold * {
    font-weight: 800 !important;
}

/* #tit */
.tit {
    margin-bottom: 10px;
}

.loading-tit {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: var(--color-verde);
}

.tit-after::before,
.tit-after::after {
    content: '';
    position: absolute;
    left: 0;
    width: 90px;
    height: 1px;
    display: block;
}

.tit-mis-recetas {
    width: 100%;
    float: left;
    color: var(--color-verde);
    margin: 40px 0 14px;
}

.tit-mis-recetas.fix {
    margin-top: 28px;
}

.tit-mis-recetas.narrow {
    margin: 0;
    color: var(--color-fff);
    margin-left: 25px;
    margin-top: 5px;
}

.folder-title-bg {
    display: flex;
    max-width: 200px;
    background-color: var(--color-verde);
    border-radius: var(--radius-12);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: 25px;
    align-self: center;
}

.folder-bg {
    background-color: var(--color-verde);
    border-radius: var(--radius-12);
    margin-bottom: 10px;
}

/* #p */
p {
    line-height: 1.5;
}

/* #a */
a {
    cursor: pointer;
    text-decoration: none;
}

/* #article */
article h2,
article h3,
article h4,
article h5,
article h6 {
    text-align: left;
    line-height: 1;
    margin-bottom: 15px;
}

article.text-center,
article.text-center * {
    text-align: center;
}

article.text-left,
article.text-left * {
    text-align: left;
}

article.text-right,
article.text-right * {
    text-align: right;
}

article.text-justify,
article.text-justify * {
    text-align: justify;
}

article p {
    width: 100%;
    float: left;
}

article p+h1,
article p+h2,
article p+h3,
article p+h4,
article p+h5,
article p+h6 {
    margin-top: 24px;
}

/* copete */
article .copete p {
    font-weight: 600;
}

article ul {
    width: 100%;
    float: left;
    margin-bottom: 15px;
    padding-left: 18px;
}

article ul li {
    color: var(--color-verde);
    width: 100%;
    float: left;
    line-height: 1.5;
    list-style: inherit !important;
}

/* ----------------------------------------------------------------------- */

/* #ICONO's */
.ico,
.ico::before,
.ico::after {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.ico::before {
    display: flex;
}

.ico-1::before {
    background-image: url('../images/svg/ico-1.svg?2');
}

.ico-2::before {
    background-image: url('../images/svg/ico-2.svg?2');
}

.ico-3::before {
    background-image: url('../images/svg/ico-3.svg?2');
}

.ico-4::before {
    background-image: url('../images/svg/ico-4.svg?2');
}

.ico-mas::before {
    background-image: url('../images/svg/ico-1.svg?2');
}

.ico-like::before {
    background-image: url('../images/svg/ico-1.svg?2');
}

.ico-impresora::before {
    background-image: url('../images/svg/ico-1.svg?2');
}

.ico-usuario::before {
    background-image: url('../images/svg/ico-usuario.svg?2');
}

.ico-estrella::before {
    background-image: url('../images/svg/ico-estrella.svg?2');
}

.ico-estrella-activo::before {
    background-image: url('../images/svg/ico-estrella-activo.svg?2');
}

.ico-lupa::before {
    background-image: url('../images/svg/ico-lupa.svg?2');
}

/* ----------------------------------------------------------------------- */

/* #BOTONES */

/* fix font */
button,
.btn {
    font-family: var(--font);
}

/* menú */
.btn-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 28px;
    margin: auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transform: translateY(25px);
    z-index: 900;
}

.btn-menu span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 80%;
    height: 2px;
    margin: auto;
    border-radius: 0;
    transition: background 0s 0.25s;
    background: var(--color-verde);
}

.btn-menu span::before,
.btn-menu span::after {
    position: absolute;
    width: inherit;
    height: inherit;
    content: '';
    transition-delay: 0.25s, 0s;
    transition-duration: 0.25s, 0.25s;
    background: var(--color-verde);
    border-radius: 0;
}

.btn-menu span::before {
    top: -8px;
    transition-property: top, -webkit-transform;
    transition-property: top, transform;
}

.btn-menu span::after {
    bottom: -8px;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
}

/* hover - activo */
body.visible-buscador .btn-menu {
    z-index: 1;
}

body.visible-menu .btn-menu span {
    width: 100%;
}

body.visible-menu #flag+span {
    background: none;
}

body.visible-menu #flag+span::before {
    top: 0;
    transform: rotate(45deg);
}

body.visible-menu #flag+span::after {
    bottom: 0;
    transform: rotate(-45deg);
}

body.visible-menu #flag+span::before,
body.visible-menu #flag+span::after {
    transition-delay: 0s, 0.25s;
}

/* buscar */
#btn-buscar {
    width: 25px;
    height: 25px;
    margin-right: 25px;
    margin-left: 5px;
}

/* ico */
#btn-buscar svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

/* hover - activo */
#btn-buscar:hover .st0 {
    fill: var(--color-gris);
}

.visible-buscador #btn-buscar svg {
    display: none;
}

.visible-buscador #btn-buscar::after {
    content: '';
    width: 22px;
    height: 22px;

    /* background-image: url('../images/svg/ico-cerrar.svg'); */

    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

/* sesion */
#btn-sesion,
#btn-vermas {
    width: 170px;
    height: 49px;
    background-color: var(--color-fff);
    border: 1px solid var(--color-verde);
    color: var(--color-gris);
    border-radius: var(--radius-12);
    font-size: 1rem;
    float: right;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
}

#btn-vermas {
    width: auto;
    height: auto;
    padding: 12px 30px;
    color: var(--color-verde);
    float: none;
    margin: auto;
}

/* ico */
#btn-sesion svg {
    width: 20px;
    height: 24px;
    margin-right: 10px;
    padding: 0, 5rem;
}

/* hover */
#btn-sesion:hover,
#btn-vermas:hover {
    background-color: var(--color-verde);
    color: var(--color-fff);
}

#btn-sesion:hover .st0,
#btn-vermas:hover .st0 {
    fill: var(--color-fff);
}

/* logueado */

.sesion-activa #btn-sesion {
    border: none;
    height: auto;
    border-radius: 0;
    color: var(--color-verde);
    font-weight: bold;
}

.sesion-activa #menu #btn-sesion {
    padding-left: 0;
    border: 0;
    margin-top: 20px !important;
}

.sesion-activa #btn-sesion svg {
    display: none;
}

.sesion-activa #btn-sesion div {
    font-weight: bold;
}

.sesion-activa #btn-sesion div::after {
    content: '';
    width: 24px;
    height: 24px;
    background-image: url('../images/svg/ico-herramientas.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px;
}

.hola {
    display: none;
    font-weight: normal !important;
}

.hola::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -45px;
    bottom: 0;
    margin: auto;
    background-color: var(--color-verde);
}

.sesion-activa .hola {
    display: block;
    margin-right: 5px;
    text-decoration: none !important;
}

.sesion-activa #btn-sesion:hover {
    background-color: transparent;
}

.sesion-activa #btn-sesion:hover div {
    text-decoration: underline;
}

.sesion-activa #btn-sesion:hover div .hola {
    text-decoration: none !important;
}

.sesion-activa #btn-sesion:hover .st0 {
    fill: var(--color-verde);
}

/* ver mas */
.cont-ver-mas {
    text-align: center;
    display: inline-block;
}

/* imprimir */
#btn-imprimir {
    color: var(--color-verde);
    font-weight: 500;
    display: flex;
    align-items: center;
}

#btn-validar {
    color: var(--color-verde);
    font-weight: 500;
}

/* ico */
#btn-imprimir svg {
    margin-left: 10px;
    width: 39px;
    height: 33px;
}

/* hover */
#btn-imprimir:hover {
    color: var(--color-gris);
}

#btn-imprimir:hover .st0 {
    fill: var(--color-gris);
}

/* filtros */
.btn-filtro {
    width: 65px;
    height: 65px;
    position: fixed;
    top: var(--top-main-buscador);
    right: 40px;
    border-radius: 50%;
    background-color: var(--color-fff);
    border: 2px solid var(--color-verde);
    margin: auto;
    z-index: 999;
    box-shadow: 0 0 45px 0 rgb(0 0 0 / 35%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    /* animation: filtro 3s infinite; */
}

/* ico */
.btn-filtro svg {
    width: 50px;
    height: 50px;
}

.btn-filtro::after {
    content: '';
    background-image: url('../images/svg/ico-cerrar.svg');
    background-position: center;
    background-size: contain;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
    transition: all 0.3s ease;
}

/* hover - visible */
.btn-filtro:hover {
    background-color: var(--color-verde);
}

.btn-filtro:hover svg .st0 {
    fill: var(--color-fff) !important;
}

.visible-filtro .btn-filtro::after {
    opacity: 1;
    visibility: visible;
    width: 20px;
    height: 20px;
}

.visible-filtro .btn-filtro svg {
    display: none;
}

.visible-filtro .btn-filtro {
    right: calc(var(--w-filtro) + 15px);
    border-color: var(--color-fff);
    animation: inherit;
}

.visible-filtro .btn-filtro:hover {
    border-color: var(--color-fff);
    background-color: var(--color-fff);
    filter: grayscale(100%);
}

@keyframes filtro {
    0% {
        transform: scale(1.06);
        box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
    }

    25% {
        transform: scale(1);
        box-shadow: 0 0 25px 0 rgb(0 0 0 / 30%);
    }

    50% {
        transform: scale(1.06);
        box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
    }

    75% {
        transform: scale(1);
        box-shadow: 0 0 25px 0 rgb(0 0 0 / 30%);
    }

    100% {
        transform: scale(1.06);
        box-shadow: 0 0 25px 0 rgb(0 0 0 / 10%);
    }
}

/* botonera filtros */
.botonera {
    width: 100%;
    float: left;
    border-radius: var(--radius-12);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.item-botonera {
    font-size: 0.9rem;
    cursor: pointer;
    border: 1px solid var(--color-gris);
    color: var(--color-gris);
    border-radius: var(--radius-8);
    padding: 5px 10px;
    margin: 0 10px 10px 0;
}

.item-botonera:last-child {
    margin-right: 0 !important;
}

.item-botonera:hover,
.item-botonera.activo {
    background-color: var(--color-verde);
    border: 1px solid var(--color-verde);
    color: var(--color-fff);
}

/*
.botonera span {
	padding: 5px 0;
}

.botonera span:after {
	content: '';
	width: 100%;
	height: 2px;
	background-color: var(--color-verde);
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	transition: all 0.3s ease;
}
*/

/* hover - activo */
.botonera a:hover span {
    font-weight: bold;
}

.botonera .activo span,
.botonera .activo span::after {
    font-weight: bold;
    opacity: 1;
}

/* ----------------------------------------------------------------------- */

/* #HEADER - #MAIN - #FOOTER */

/* header */
#header {
    padding: 0;
    position: fixed;
    z-index: 700;
    background: var(--color-fff);
    border-bottom: 1px solid var(--color-eee);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    will-change: auto;
}

.visible-sesion #header,
.visible-sesion #hamburger {
    z-index: 998;
}

#header,
#header .container,
#header .cyg-row,
#header .cyg-col-full,
#header .cyg-row>div {
    height: var(--h-header);
}

/* row */
#header .cyg-row .cyg-col-full {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* fix alineacion */
#header .cyg-row div {
    display: flex;
    align-items: center;
}

#header .autocomplete-input {
    display: flex;
}

#header .autocomplete-input>div {
    width: 100%;
    height: auto;
}

#header .autocomplete-input>div .wrapper {
    top: -22px;
}

/* logo */
#logo {
    margin: 0;
    padding: 0;
    line-height: 1;
    float: left;
    transition: all 0.3s ease;
    will-change: auto;
}

#logo a {
    display: block;
    float: left;
    line-height: 0;
}

#logo span {
    display: none;
    visibility: hidden;
}

#logo img {
    height: 70px;
    transition: all 0.3s ease;
    will-change: auto;
}

/* main */
#main {
    padding-top: var(--top-main);
    padding-bottom: 90px;
    z-index: 600;
}

.sidebar-background.activo {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 990;
}

.sidebar-background.activo::after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(255 255 255 / 50%);
    z-index: 998;
    visibility: hidden;
    transition: all 0.4s ease;
}

.visible-filtro .sidebar-background::after {
    visibility: visible;
}

.visible-filtro #main {
    z-index: 900;
}

/* .visible-buscador #main {
    padding-top: var(--top-main-buscador);
} */

/* footer */
#footer {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--color-fff);
    padding: 0;
    display: none !important;
}

/* row */
#footer .cyg-col-full {
    align-items: center;
    justify-content: space-between;
}

/* address */
#footer address {
    max-width: 180px;
}

#footer address a {
    color: var(--color-gris);
    margin: 0 0 15px;
    clear: both;
    display: inline-block;
}

#footer address br {
    display: none;
}

#footer address a::before {
    content: '';
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#footer address .ico-tel::before {
    background-image: url('../images/svg/ico-tel.svg');
}

#footer address .ico-correo::before {
    background-image: url('../images/svg/ico-correo.svg');
}

/* hover */
#footer address a:hover {
    color: var(--color-verde);
}

/* redes */
.mod-redes {
    position: absolute;
    width: 170px;
    height: 40px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.mod-redes a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: var(--color-verde);
    overflow: hidden;
}

.mod-redes img {
    width: 38px;
    height: 38px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.mod-redes a:hover {
    background-color: var(--color-gris);
}

/* nav */
#footer nav {
    max-width: 318px;
    display: flex;
}

#footer nav div {
    padding-left: 15px;
}

#footer nav a {
    color: var(--color-gris);
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

#footer nav a:hover {
    color: var(--color-verde);
}

/* ----------------------------------------------------------------------- */

/* #NAVS's' */

/* #menú */
.menu {
    width: auto;
    height: auto;
    float: right;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu a {
    color: var(--color-gris);
    font-weight: 600;
    padding: 0;
    margin-right: 25px;
    text-decoration: underline;
}

/* hover - activo */
.menu a:hover,
.menu [data-pagina]:hover,
.menu a.activo {
    color: var(--color-verde);
}

/* fix btn sesion */
.menu #btn-sesion {
    display: none;
}

/* sub menu (herramientas) */
.sub-menu {
    border-bottom-right-radius: var(--radius-8);
    border-bottom-left-radius: var(--radius-8);
    background-color: var(--color-fff);
    max-width: 300px;
    float: left;
    border: 1px solid var(--color-eee);
    border-top: none !important;
    position: absolute;
    top: 100%;
    right: 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transform: translateY(-1px);
}

.sub-menu li {
    width: 100%;
    float: left;
    list-style: none;
    display: none;
}

.sub-menu li a {
    width: 100%;
    float: left;
    color: var(--color-gris);
    padding: 10px 0;
    border-bottom: 1px solid var(--color-eee);
    font-weight: 600;
    font-size: 0.85rem;
    text-align: right;
}

.sub-menu li:last-child a {
    border-bottom: 0 !important;
}

/* hover */
.sub-menu li a:hover {
    color: var(--color-verde);
}

.sub-menu.activo,
#btn-sesion:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    height: auto;
}

.sub-menu.activo li,
#btn-sesion:hover .sub-menu li {
    display: block;
}

/* ----------------------------------------------------------------------- */

/* #FORM's */

/* fix ios */
textarea,
input,
input[type='text'],
input[type='button'],
input[type='submit'] {
    appearance: none;
    border-radius: 0;
}

:focus,
.btn:focus,
.input:focus,
.textarea:focus,
.select:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: var(--color-verde);
}

.btn:focus {
    color: var(--color-fff) !important;
}

.btn:focus:hover {
    color: var(--color-verde) !important;
}

div:focus {
    border-color: var(--color-verde) !important;
}

label,
input,
textarea,
select {
    width: 100%;
    float: left;
    font-family: var(--font);
}

/* textarea */
textarea {
    height: 80px;
}

/* select */
select {
    cursor: pointer;
    appearance: none;
    background-image: url('../images/svg/ico-select.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-color: transparent;
    background-size: 9px;
}

/* focus */
select:focus {
    background-image: url('../images/svg/ico-select-focus.svg');
    border: 1px solid var(--color-666);
}

/*
input:focus,
textarea:focus {
    outline: 0;
    border-color: #fff;
}
*/
/* checkbox - radio */
input[type='checkbox'],
input[type='radio'] {
    display: none;
}

label.check,
label.radio {
    display: inline-flex;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
}

label.check i,
label.radio i {
    width: 21px;
    height: 21px;
    border: 1px solid var(--color-ccc);
    background-color: var(--color-fff);
    float: left;
    margin-right: 8px;
    position: relative;
    overflow: hidden;
}

label.check i {
    border-radius: 0;
}

label.radio i {
    border-radius: 50%;
}

input[type='checkbox'].activo+label i::before,
input[type='radio'].activo+label i::before {
    content: '';
    width: 9px;
    height: 9px;
    position: absolute;
    background-color: var(--color-verde);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    border-radius: 50%;
}

input[type='check']:checked+label i::before,
input[type='check'].activo+label i::before {
    border-radius: 0;
}

input[type='radio']:checked+label i::before,
input[type='radio'].activo+label i::before {
    border-radius: 50%;
}

/* hover */
label.check:hover i,
label.radio:hover i {
    border-color: var(--color-verde);
}

label.check:hover label.radio:hover {
    color: var(--color-verde);
}
/*form-activation*/
.form-activation {
    padding: 10%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.activation-container {
    max-width: 500px;
    display: grid;
}

.form-activation h6 {
    text-align: left;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 2rem;
    color: var(--color-verde);
}

.form-activation label {
    color: var(--color-333);
    margin-bottom: 10px;
}

.form-activation input {
    height: 50px;
    border-radius: var(--radius-8);
    padding: 10px 15px;
    background-color: var(--color-fff);
    border: 1px solid var(--color-ddd);
    margin-bottom: 15px;
    color: var(--color-gris);
    font-size: 0.9rem;
    font-weight: 400;
}

/* button */
.form-activation button {
    background-color: var(--color-verde);
    border-radius: 15px;
    border: 1px solid var(--color-verde);
    color: var(--color-fff);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    justify-self: center;
    margin-top: 10px;
    padding: 15px;
    text-align: center;
    width: 200px;
}

/* hover */
.form-activation button:hover {
    background-color: var(--color-fff);
    color: var(--color-verde);
}

.form-activation footer a {
    color: var(--color-gris);
    font-size: 0.9rem;
    text-align: center;
    margin: 0 10px;
    text-decoration: underline;
}

.form-activation footer a:hover {
    color: var(--color-verde);
}


/* form register */
.form-register {
    padding: 10%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.register-container {
    max-width: 500px;
    display: grid;
}

.form-register h6 {
    text-align: left;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 2rem;
    color: var(--color-verde);
}

.form-register label {
    color: var(--color-333);
    margin-bottom: 10px;
}

.form-register input {
    height: 50px;
    border-radius: var(--radius-8);
    padding: 10px 15px;
    background-color: var(--color-fff);
    border: 1px solid var(--color-ddd);
    margin-bottom: 15px;
    color: var(--color-gris);
    font-size: 0.9rem;
    font-weight: 400;
}

.select-language {
    display: inline-block;

    margin-bottom: 15px;
   width: 100%;
   
}

  
  .selectLang__control {
    min-height: 50px;
    height: 50px;
  }
  
  .selectLang__value-container {
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  
  .selectLang__input-container {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .selectLang__input {
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  
  /* Valor seleccionado */
  .selectLang__single-value {
    line-height: 1;
  }
  
  .selectLang__indicator,
  .selectLang__dropdown-indicator,
  .selectLang__clear-indicator {
    padding: 8px;
  }
  
  
  
  
  
  
/* button */
.form-register button {
    background-color: var(--color-verde);
    border-radius: 15px;
    border: 1px solid var(--color-verde);
    color: var(--color-fff);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    justify-self: center;
    margin-top: 10px;
    padding: 15px;
    text-align: center;
    width: 200px;
}

/* hover */
.form-register button:hover {
    background-color: var(--color-fff);
    color: var(--color-verde);
}

/* form ichef */
.form-ichef {
    width: 100%;
    float: left;
    position: relative;
}

/* header */
.form-ichef header {
    text-align: left;
}

.form-ichef h6 {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-verde);
}

/* input - select - textarea */
.form-ichef input,
.form-ichef select,
.form-ichef textarea {
    height: 50px;
    border-radius: var(--radius-8);
    padding: 10px 15px;
    background-color: var(--color-fff);
    border: 1px solid var(--color-ddd);
    margin-bottom: 15px;
    color: var(--color-gris);
    font-size: 0.9rem;
    font-weight: 600;
}

.form-ichef textarea {
    height: 120px;
}

/* button */
.form-ichef button {
    background-color: var(--color-verde);
    border: 1px solid var(--color-verde);
    text-align: center;
    color: var(--color-fff);
    padding: 10px;
    width: auto;
    border-radius: 9px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 15px;
}

/* hover */
.form-ichef button:hover {
    background-color: var(--color-fff);
    color: var(--color-verde);
}

/* form herramientas */
.form-ichef.herramientas {
    max-width: 480px;
    margin-bottom: 15px;
}

.form-ichef.herramientas button {
    margin: 15px 0;
}

.form-ichef .span-label {
    width: 100%;
    float: left;
    font-size: 0.9rem;
    margin-bottom: 5px;
}

/* checkbok */
.form-ichef label.check,
.form-ichef label.radio {
    float: left;
    margin-right: 30px;
    display: inline-flex;
    width: auto;
}

/* form sesion */
#sesion .form-ichef {
    width: 300px;
    height: auto;
    background-color: var(--color-fff);
    padding: 30px;
    border-radius: var(--radius-12);
    border: 1px solid var(--color-ddd);
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
}

#sesion .form-ichef header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-ichef h6 svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
}

#sesion .form-ichef button {
    width: 100%;
}

.form-ichef footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 10px 0;
}

.form-ichef footer a {
    color: var(--color-gris);
    font-size: 0.9rem;
    text-align: center;
    margin: 0 10px;
    text-decoration: underline;
}

.form-ichef footer a:hover {
    color: var(--color-verde);
}

/* visible form */
.visible-sesion {
    overflow: hidden;
}

.visible-sesion #sesion {
    z-index: 999;
    opacity: 1;
}

.visible-sesion #sesion .form-ichef {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

/* autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-666);
    box-shadow: 0 0 0 1000px #f5f5f5 inset;
    transition: background-color 5000s ease-in-out 0s;
}

/* placeholder */
input::placeholder {
    color: var(--color-placeholder);
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
}

textarea::placeholder {
    color: var(--color-placeholder);
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
}

/* form dentr de herramientas */
.col-form {
    margin: 0 0 15px;
    padding: 14px;

    /* background-color: var(--color-eee); */
    background-color: #f5f5f5;
    border-radius: var(--radius-12);
}

/* h6 */
.col-form h6 {
    font-weight: 600;
    margin-bottom: 7px;
}

/* form actualizar */
.col-form-actualizar-recetas {
    margin-top: 15px;
}

/* form pin */
.col-form.col-form-pin form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 14px;
}

.col-form.col-form-pin form input {
    width: calc(100% - 127px);
    float: left;
    margin: 0;
}

.col-form.col-form-pin form input:nth-of-type(2) {
    color: var(--color-verde) !important;
    font-size: 1.15rem !important;
}

/* button */
.col-form form button {
    margin: 0;
}

.col-form.col-form-pin form button {
    width: 120px;
    height: 50px;
    float: right;
}

.flag-pd {
    padding-right: 5px;
}

/* ----------------------------------------------------------------------- */

/* # EFECTO's */

.outline {
    border: 5px solid var(--color-verde);
}

.transition,
.st0 {
    transition: all 0.3s ease-in;
    will-change: auto;
}

.recipe-transition {
    transition: all 0.3s ease-in;
    transition-delay: 0.4s;
    will-change: auto;
}

/* scroll-down */
.scroll-down #header {
    height: var(--h-header-scroll);
}

.scroll-down #header,
.scroll-down #header .container,
.scroll-down #header .cyg-row,
.scroll-down #header .cyg-col-full,
.scroll-down #header .cyg-row>div {
    height: var(--h-header-scroll);
}

.scroll-down #logo img {
    height: 35px;
}

.scroll-down #btn-sesion {
    height: 40px;
    width: auto;
    padding: 0 12px;
}

.sesion-activa.scroll-down #btn-sesion {
    height: auto;
    padding-right: 0;
    padding-left: 40px;
}

.scroll-down #btn-sesion svg,
.scroll-down #btn-sesion div {
    transform: scale(0.9);
}

.scroll-down .btn-menu {
    transform: translateY(10px);
}

.scroll-down.visible-buscador #buscador {
    top: var(--h-header-scroll) !important;
    height: var(--h-buscador-scroll);
}

.scroll-down.visible-buscador #buscador form {
    top: 62px;
}

/* ----------------------------------------------------------------------- */

/* #MOD's */

/* #acordeon */
.mod-acordeon {
    padding: 0;
    margin: 0;
}

/* link */
.item-aco {
    width: 100%;
    float: left;
    cursor: pointer;
    list-style: none;
    margin-bottom: 0;
    padding: 10px 0;
    font-weight: 500;
    color: #999;
    border-top: 1px solid var(--color-verde-rgba-1);
    font-size: 1.05rem;
}

.item-aco::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    margin: auto;
    background-image: url('../images/svg/ico-flecha-derecha.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* content */
.mod-acordeon .content {
    width: 100%;
    float: left;
    padding: 0;
    height: 0;
    visibility: hidden;
    transition: all 0.6s ease;
}

.mod-acordeon .content * {
    display: none;
}

/* hover - activo */
.item-aco.activo,
.item-aco:hover {
    color: var(--color-verde);
}

.item-aco.activo::after {
    transform: rotate(90deg);
}

.item-aco.activo+.content,
.mod-acordeon .content.activo {
    height: auto;
    visibility: visible;
    padding: 5px 0;
}

.mod-acordeon .item-aco.activo+.content * {
    display: block;
}

/* #buscador */
#buscador {
    width: 100%;
    height: var(--h-buscador);
    float: left;
    text-align: center;
    background-color: var(--color-fff);
    border-bottom: 1px solid var(--color-eee);
    overflow: hidden;
}

header#buscador {
    display: none;
}

/* container */
#buscador .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 10;
}

/* tit */
#buscador h3 {
    float: left;
    font-weight: bold;
    padding-bottom: 8px;
    left: 15px;
}

#buscador h3::before {
    content: '';
    width: 50px;
    height: 3px;
    background-color: var(--color-verde);
    position: absolute;
    bottom: 0;
    left: 0;
}

/* bg */
#buscador .bg-textura {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
}

.visible-buscador #buscador .bg-textura {
    opacity: 0.5;
}

/* form */
#buscador form {
    width: 500px;
    max-width: 500px;

    /* height: auto; */
    min-height: 46px;
    margin: auto;
    overflow: hidden;
    transition: all 0.4s ease;

    /* box-shadow: 0 0 20px 0 rgb(0 0 0 / 5%); */
    box-shadow: none !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(100px);
    height: auto;
    background-color: var(--color-fff);
    position: fixed;
    z-index: 99;
    top: 150px;
    right: 0;
    left: 0;
    border-radius: var(--radius-8);
    border: 1px solid var(--color-verde);
}

#buscador form.search-results-open {
    height: 230px;
    border: 1px solid var(--color-verde);
    box-shadow: none !important;

    /* overflow-y: auto; */
}

#buscador .cmyNjn {
    width: 500px;
    min-height: 46px;
    position: absolute;
}

/* wrapper */
#buscador form .wrapper {
    background-color: transparent !important;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    width: 100%;
}

#buscador form .wrapper svg {
    display: none;
}

/*
#buscador form:hover::-webkit-scrollbar {
    width   : 5px !important;
    position: fixed !important;
    top     : 0;
    right   : 0
}
*/

/* input */
#buscador form input {
    background-color: var(--color-fff);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 90;
    padding: 10px;
    color: var(--color-placeholder);
    font-weight: 700;
    height: 100%;
    box-shadow: none !important;
}

#buscador form.search-results-open input {
    border-bottom: none !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none !important;
}

/* button */
#buscador form button {
    width: 50px;
    height: 46px;
    position: absolute;
    top: 0;
    right: 5px;
    background-color: transparent !important;
    border: 0 !important;
}

#buscador form button svg {
    width: 25px;
    height: 25px;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

#buscador form button svg:hover .st0 {
    fill: var(--color-gris);
}

#content {
    display: flex;
    flex-direction: column;
}

/* visible */
.visible-buscador #buscador {
    /* position: fixed;
	top: var(--h-header);
	z-index: 900; */

    display: none !important;
}

.visible-buscador #buscador .search-form {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.visible-buscador #content {
    z-index: 899;
}

/* #intro */
#intro {
    margin-bottom: 60px;
}

/* cont */
#intro .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    margin: auto;
}

/* info receta */
#intro .cyg-col-left {
    padding-right: 0;
    background-color: var(--color-fff);
}

#intro .cyg-col-left .cont {
    padding: 20px 15px;
}

#intro .cyg-col-right {
    margin: 0 !important;
}

#intro .cyg-col-right .cont {
    padding: 40px 30px;
    width: 100%;
    height: 100%;
    float: left;
}

#intro .cyg-col-left::after,
#intro .cyg-col-right::after {
    content: '';
    position: absolute;
    bottom: 0;
    margin: auto;
    width: calc(100% - 7px);
    height: 8px;
    background: var(--color-verde);
}

#intro .cyg-col-left::after {
    left: 0px;
}

#intro .cyg-col-right::after {
    right: 7px;
}

#intro .cyg-col-right::before {
    content: '';
    width: calc(100% - 7px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-fff);
}

/* tx */
#intro h2 {
    color: var(--color-verde);
    line-height: 1.2;
    font-weight: 600;
}

#intro .mod-botones {
    margin-top: 20px;
    justify-content: center;
}

/* bg (fix) */
#intro .bg-textura {
    height: 700px;
}

#intro .bg-textura-ia {
    height: 500px;
}

#intro .bg-textura::after {
    content: '';
    width: 100%;
    height: 170px;
    background: var(--color-fff);
    position: absolute;
    left: 0;
    bottom: 0;
}

/* linea */
#intro .linea {
    width: calc(100% - 15px);
    height: 8px;
    display: none;
    opacity: 0;
}

/* #INFO */
.info .cyg-col-right {
    padding-left: 0;
}

/* #mod */
.mod {
    margin-bottom: 35px;
    padding-bottom: 35px;
}

/* tit */
.mod .tit h3 {
    font-weight: 700;
}

/* linea */
.mod::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--color-verde-rgba-1);
    position: absolute;
    left: 0;
    bottom: 0;
}

/* preparacion */
.mod-preparacion article h5 {
    color: var(--color-verde);
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 8px;
}

.mod-preparacion article h6 {
    color: var(--color-gris);
    font-weight: 500;
    margin-bottom: 3px;
}

.mod-preparacion article ul {
    list-style: none;
    padding-left: 0;
}

.mod-preparacion article ul li {
    color: var(--color-gris);
}

/* etiquetas */
.etiqueta,
.info .etiqueta:hover {
    width: auto;
    padding: 5px 10px;
    background-color: var(--color-fff);
    border: 1px solid var(--color-verde);
    color: #999;
    font-weight: 500;
    border-radius: var(--radius-8);
    margin: 0 10px 10px 0;
    float: left;
    cursor: pointer;
    font-size: 0.9rem;
    font-family: 'Fira Sans', sans-serif;
}

.info .etiqueta,
.info .etiqueta a {
    cursor: default !important;
}

/* hover */
.etiqueta:hover {
    opacity: 0.8;
}

.etiqueta.activo {
    background-color: var(--color-verde);
    border: 1px solid var(--color-verde);
    color: var(--color-fff);
}

.etiqueta.activo:hover {
    opacity: 0.8;
}

.info .etiqueta,
.info .etiqueta:hover {
    cursor: default !important;
}

/* mod imprimir */
.mod-imprimir {
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 40px;
}

/* mod cat sel (home) */
.mod-cat-sel {
    padding: 0 30px 5px;
}

.mod-cat-sel p {
    margin: 0 35px 0 0;
}

.mod-cat-sel b {
    font-weight: 700;
}

.mod-cat-sel span {
    margin: 10px 0 0 15px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

.mod-cat-sel span::after {
    content: '';
    margin-left: 3px;
    font-weight: 600;
    background-image: url('../images/svg/ico-cerrar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
    display: block;
}

/* hover */
.mod-cat-sel span:hover {
    color: var(--color-verde);
}

.mod-cat-sel .d-flex {
    flex-direction: column;
}

.folder-scroll {
    display: flex;
    flex-wrap: wrap;
}

.selector {
    width: 160px;
    height: 35px;
    margin-left: 10px;
}

/* #grilla recetas */
.grilla {
    flex-wrap: wrap;
    padding: 0;
}

/* grilla */
.grilla .cont-receta {
    -webkit-box-flex: 0;
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
    padding: 7px;
    cursor: pointer;
}

/* receta */
.receta {
    border-radius: var(--radius-12);
    overflow: hidden;
    width: 100%;
    float: left;
}

.receta-usuario{
    border: 5px solid rgba(255, 0, 0, 0.4);
}

/* receta receta.gr (hover) */
.receta.gr {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 900;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden;
    padding-top: 132%;
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 95%);
    opacity: 0;
    visibility: hidden;
}

/* botones */
.mas,
.like,
.opciones,
.variantes,
.editar,
.ichef--opciones {
    position: absolute;
    z-index: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #000;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: icomoon !important;
    cursor: pointer;
}

/* mas */
.mas::before {
    content: '\e912';
    font-size: 26px;
}

.mas.activo::before {
    content: '\e914';
}

/* like */
.like::before {
    content: '\e910';
    font-size: 26px;
}

/* editar - opciones */
.editar::before,
.opciones::before,
.variantes::before {
    content: '';
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.editar::before,
.editar.activo::before,
.editar:hover::before {
    background-image: url('../images/svg/ico-lapiz-activo.svg');
}

.ichef--opciones .opciones {
    right: 0 !important;
}

.ichef--opciones .variantes {
    right: 0 !important;
}

.opciones::before,
.opciones.activo::before,
.opciones:hover::before {
    background-image: url('../images/svg/ico-opciones-activo.svg');
}

.variantes::before,
.variantes.activo::before,
.variantes:hover::before {
    background-image: url('../images/svg/ico-variantes-activo.svg');
}

.clonacion::before,
.clonacion.activo::before,
.clonacion:hover::before {
    background-image: url('../images/svg/ico-variantes-activo.svg');
}

.ichef--opciones .sub--menu {
    width: 110px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 15px;
    border: 1px solid var(--color-verde);
    border-radius: var(--radius-8);
    background-color: #fff;
    font-family: var(--font);
    z-index: 900;
    transform: translateX(-35px);
}

.ichef--opciones .sub--menu a {
    width: 100%;
    float: left;
    font-size: 0.6rem;
    color: var(--color-gris);
    padding: 5px 0;
}

.ichef--opciones .sub--menu a:nth-child(1) {
    border-bottom: 1px solid #ddd;
}

.ichef--opciones .sub--menu a:nth-child(3) {
    border-top: 1px solid #ddd;
}

.ichef--opciones .sub--menu a:hover {
    color: var(--color-verde);
}

/* hover gral */
.mas:hover,
.mas.activo,
.like:hover,
.like.activo,
.opciones:hover,
.opciones.activo,
.variantes:hover,
.variantes.activo,
.editar:hover,
.editar.activo {
    background-color: var(--color-verde);
}

/* posiciones */
.top-1 {
    top: 10px;
}

.top-2 {
    top: 45px;
}

.top-3 {
    top: 80px;
}

.top-4 {
    top: 115px;
}

/* img */
.receta.gr img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

/* hover receta gr */
.cont-receta:hover .receta.gr {
    transform: scale(1.3);
    opacity: 1;
    visibility: visible;
}

/* footer */
.receta footer,
.receta .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 18px;
    z-index: 90;
    margin: auto;
}

.receta.gr footer,
.receta.gr .footer {
    bottom: 80px;
    padding: 10px 15px;
}

/* estrellas */
.receta .mod-estrellas {
    margin-bottom: 3px;
}

.receta.gr .mod-estrellas {
    margin-bottom: 0;
}

.receta .mod-estrellas svg {
    width: 14px;
    height: 13px;
}

.receta .mod-estrellas svg .st0 {
    fill: transparent !important;
}

.receta .mod-estrellas svg .st1 {
    fill: var(--color-fff) !important;
}

.receta .mod-estrellas .activo svg .st0 {
    fill: var(--color-fff) !important;
}

/* nombre */
.receta h2 {
    color: var(--color-fff);
    font-size: 0.85rem;
    font-weight: 300;
}

.receta h4 {
    color: var(--color-fff);
    font-size: 0.75rem;
    font-weight: 200;
}

.receta.gr h2 {
    font-weight: 400;
}

.receta.gr h4 {
    font-weight: 300;
}

/* img */
.receta.ch figure {
    z-index: 80;
}

/* bg */
.receta .bg-degrade {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 85;
    width: 100%;
    height: 85%;
    opacity: 1;
}

/* mod ingredientes */
.mod-ingredientes {
    padding: 20px;
}

.porciones {
    margin: 15px;
}

.ingr-warning {
    padding: 5px;
    color: black;
    text-align: center;
}

/* tit */
.mod-ingredientes h3 {
    font-weight: 700;
}

/* tx */
.mod-ingredientes p {
    margin-bottom: 10px;
    font-weight: 500;
}

.mod-ingredientes p:last-child {
    margin-bottom: 0;
}

.mod-input-container {
    margin-bottom: 10px;
}

.editing-text {
    border: 1px var(--color-verde) solid;
    padding: 5px !important;
    font-size: 0.9rem;
    color: #555;
    min-height: 60px;
}

/* mod ingredientes */
.mod-valores {
    padding: 35px;
    background-color: #f7f7f7;
}

/* tit */
.mod-valores h3 {
    font-weight: 700;
}

.mod-valores h6 {
    margin-top: 5px;
    font-weight: 500;
}

/* table */
.mod-valores table {
    width: 100%;
}

.mod-valores th {
    font-weight: 500;
    text-align: left;
}

.mod-valores td {
    font-weight: 400;
    text-align: right;
}

.mod-valores th,
.mod-valores td {
    padding: 5px 0;
}

.edit-valores header {
    margin-bottom: 0;
}

/* #filtros */
#filtros {
    width: var(--w-filtro);
    height: 100vh;
    background-color: var(--color-fff);
    padding: 35px;
    z-index: 998;
    position: fixed;
    right: 0;
    top: 0;
    box-shadow: 0 50px 50px 0 rgb(0 0 0 / 35%);
    overflow: auto;
    transform: translateX(500px);
    transition: all 0.3s ease;
    overflow-x: hidden;
}

/* tit */
#filtros h4 {
    font-weight: 600;
    color: var(--color-verde);
    margin-bottom: 25px;
}

/* secciones */
#filtros section {
    margin-bottom: 20px;
}

#filtros h5 {
    color: var(--color-gris);
    font-weight: 600;
    margin-bottom: 10px;
}

/* cate */
.cate form {
    overflow-y: scroll;
    max-height: 130px;
}

.clear-btn {
    display: inline-flex;
    align-items: center;
}

.clear-btn::after {
    content: '';
    margin-left: 3px;
    font-weight: 600;
    background-image: url('../images/svg/ico-cerrar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
    display: block;
}

.clear-btn:hover::after {
    background-image: url('../images/svg/ico-cerrar-blanco.svg');
}

/* mostrar - ocultar */

/* body.visible-filtro {
    overflow: hidden !important;
} */

.visible-filtro #filtros {
    transform: translateX(0);
}

/* iniciar sesion */
.cyg-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;

    /* background-color : rgb(255 255 255 / 90%); */
    background-color: rgb(81 158 44 / 50%);

    /* background-color: var(--color-verde-rgba-2); */

    z-index: 0;
    opacity: 0;
}

/* ico */
.cyg-lightbox i.btn-close {
    width: 25px;
    height: 25px;
    background-image: url('../images/svg/ico-cerrar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

.cyg-lightbox i:hover {
    filter: grayscale(100%);
}

/* #msj */

.sec-msj span {
    margin-top: 5px !important;
}

.msj {
    width: 100%;
    float: left;
    padding: 5px;
    display: flex;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    margin: 10px 0;
    border-radius: 12px;
    color: #000;
}

.msj-dlg {
    align-items: center;
    border-radius: 12px;
    color: #000;
    display: flex;
    float: left;
    margin: 10px 0;
    padding: 5px;
    width: 100%;
}

.msj-dlg::before,
.msj::before {
    content: '';
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
}

.msj-dlg.ok {
    border-color: #008c17;
    font-weight: 400;
}
.msj-dlg.error {
    border-color: #b50000;
    font-weight: 400;
}


/* ok */

.msj.ok,
.msj-ok {
    border-color: #008c17;
    background-color: var(--color-verde-rgba-1);
    font-weight: 400;
}

.msj-dlg.ok::before,
.msj.ok::before,
.msj-ok::before {
    background-image: url('../images/svg/ico-ok.svg');
}



/* alert */
.msj.alert,
.msj-alert {
    border-color: #d1bf00;
    color: #d1bf00;
    background-color: rgb(209 191 0 / 20%);
    font-size: 0.8rem;
    font-weight: 400;
}


.msj.alert::before,
.msj-alert::before {
    background-image: url('../images/svg/ico-alerta.svg');
}

/* error */
.msj.error,
.msj-error {
    border-color: #b50000;
    background-color: rgb(181 0 0 / 20%);
    font-weight: 400;
}

.msj-dlg.error::before,
.msj.error::before,
.msj-error::before {
    background-image: url('../images/svg/ico-error.svg');
}

/* ESTRELLAS */
.mod-estrellas svg {
    margin-right: 2px;
    width: 24px;
    height: 22px;
}

.mod-estrellas .activo .st0 {
    fill: var(--color-gris) !important;
}

.mod-estrellas .btn-calificar.btn {
    margin-left: 15px;
    transform: translateY(-2px);
}

/* loghtbox */
.cyg-lightbox.score section.cont-score {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.cyg-lightbox.score section.cont-score .mod-estrellas {
    width: auto !important;
}

.cyg-lightbox.score p {
    text-align: center;
    margin-top: 5px;
}

/* ICONOS RECETA */

/* receta pg */

.mod-iconos {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    bottom: 20px;
    padding: 0 35px;
    display: flex;
    justify-content: space-between;
}

.mod-iconos div {
    text-align: center;
    float: left;
}

/* facil */
.facil {
    width: 75px;
}

.facil svg {
    max-width: 48px;
}

/* minutos */
.min {
    width: 60px;
}

/* tiempo */
.tiempo {
    width: 60px;
}

/* preparacion */
.prep {
    width: 85px;
}

.mod-iconos svg {
    height: 52px;
}

/* ico */
.mod-iconos i {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto !important;
    text-align: center;
}

/* tx */
.mod-iconos span {
    width: 100%;
    float: left;
    color: var(--color-verde);
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
    margin: 4px auto 0;
    text-align: center;
}

/* receta grilla */
.cont-receta .mod-iconos {
    bottom: 15px;
    padding: 0;
    z-index: 980;
    transform: translateX(0);
}

.cont-receta .mod-iconos i,
.cont-receta .mod-iconos svg {
    height: 30px;
    font-size: 24px;
}

.cont-receta .mod-iconos svg .st0,
.cont-receta .mod-iconos svg path {
    fill: #fff;
}

.cont-receta .mod-iconos span {
    font-size: 0.53rem;
    color: var(--color-fff);
}

.cont-receta .icon-svg {
    font-size: 1.1rem;
    color: var(--color-fff);
}

/* mod botones */
.mod-botones i,
.mod-botones a {
    border-radius: 50%;
    border: 2px solid var(--color-verde);
    width: 45px;
    min-width: 45px;
    height: 45px;
    background-color: var(--color-fff);
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.red {
    border: 2px solid #FF3030 !important;
}

.mod-botones svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
}

.mod-botones .btn-editar:hover {
    background-color: var(--color-verde);
}

/* editar - opciones */
.mod-botones .btn-editar::before,
.mod-botones .btn-opciones::before,
.mod-botones .btn-borrar::before,
.mod-botones .btn-agregar-carpeta::before,
.mod-botones .btn-comentarios::before,
.mod-botones .btn-comentarios2::before,
.mod-botones .btn-clonacion::before,
.mod-botones .btn-variantes::before {
    content: '';
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.mod-botones .btn-editar::before {
    background-image: url('../images/svg/ico-lapiz.svg');
}

.mod-botones .btn-opciones::before {
    background-image: url('../images/svg/ico-opciones.svg');
}

.mod-botones .btn-borrar::before {
    background-size: 48px;
    background-image: url('../images/new/receta_del.svg');
}

.mod-botones .btn-agregar-carpeta::before {
    background-size: 48px;
    background-image: url('../images/new/carpeta_add.svg');
}

.mod-botones .btn-comentarios::before {
    background-size: 48px;
    background-image: url('../images/new/resenas_list.svg');
}

.mod-botones .btn-comentarios2::before {
    background-size: 48px;
    background-image: url('../images/new/resenas_list2.svg');
}

.mod-botones .btn-variantes::before {
    background-image: url('../images/svg/ico-variantes.svg');
}

.mod-botones .btn-clonacion::before {
    background-image: url('../images/svg/ico-variantes.svg');
}

.mod-botones .btn-editar.activo::before,
.mod-botones .btn-editar:hover::before {
    background-image: url('../images/svg/ico-lapiz-activo.svg');
}

.mod-botones .btn-opciones.activo::before,
.mod-botones .btn-opciones:hover::before {
    background-image: url('../images/svg/ico-opciones-activo.svg');
}

.mod-botones .btn-borrar.activo::before,
.mod-botones .btn-borrar:hover::before {
    background-size: 48px;
    background-image: url('../images/new/receta_del_.svg');
}

.mod-botones .btn-agregar-carpeta.activo::before,
.mod-botones .btn-agregar-carpeta:hover::before {
    background-size: 48px;
    background-image: url('../images/new/carpeta_add_active.svg');
}

.mod-botones .btn-comentarios.activo::before,
.mod-botones .btn-comentarios:hover::before {
    background-size: 48px;
    background-image: url('../images/new/resenas_list_active.svg');
}

.mod-botones .btn-comentarios2.activo::before,
.mod-botones .btn-comentarios2:hover::before {
    background-size: 48px;
    background-image: url('../images/new/resenas_list2_active.svg');
}

.mod-botones .btn-variantes.activo::before,
.mod-botones .btn-variantes:hover::before,
.mod-botones .btn-clonacion:hover::before {
    background-image: url('../images/svg/ico-variantes-activo.svg');
}

/* fix ico mas */
.ico-mas #ico-mas {
    display: block;
}

.ico-mas #ico-mas-activo {
    display: none;
}

.ico-mas.activo #ico-mas {
    display: none;
}

.ico-mas.activo #ico-mas-activo {
    display: block;
}

/* intro */
.mod-botones i:hover,
.mod-botones i.activo {
    background-color: var(--color-verde);
}

.mod-botones .red:hover {
    background-color: #FF3030 !important;
}

.mod-botones i:hover .st0,
.mod-botones i.activo .st0 {
    fill: #fff !important;
    stroke: #fff !important;
}

/* intro */
#intro .mas,
#intro .like {
    position: relative;
    top: auto !important;
    right: auto !important;
    float: left !important;
    color: var(--color-verde);
    background-color: var(--color-fff);
}

#intro .mas::before,
#intro .like::before {
    font-size: 36px;
}

#intro .mas:hover,
#intro .mas.activo,
#intro .like:hover,
#intro .like.activo {
    color: var(--color-fff);
    background-color: var(--color-verde);
}

/* herramientas */
.nav-herr {
    width: 200px;
    float: left;
    color: var(--color-verde-rgba-1);
}

.nav-herr a {
    width: 100%;
    float: left;
    cursor: pointer;
    list-style: none;
    margin-bottom: 0;
    padding: 10px 0;
    font-weight: 500;
    color: #999;
    border-top: 1px solid var(--color-verde-rgba-1);
    font-size: 1.05rem;
}

.nav-herr a:first-child {
    border-top: none !important;
}

.nav-herr a::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    margin: auto;
    background-image: url('../images/svg/ico-flecha-derecha.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center;
}

.nav-herr a.activo,
.nav-herr a:hover {
    color: var(--color-verde);
}

.sec-herr {
    width: calc(100% - 200px);
    padding: 40px;
}

.sec-herr .cyg-row {
    float: left;
}

.sec-herr .cyg-col-full {
    max-width: 500px;
}

/* usuaruio */
.usuario-herr {
    margin-bottom: 20px;
}

.usuario-herr span {
    font-weight: 600;
}

/* estado */
.estado,
.estado section,
.estado span {
    width: 100%;
    float: left;
}

.estado {
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-ddd);
    padding-bottom: 8px;
}

.estado:last-child {
    border-bottom: none;
}

/* .estado span{

} */

.estado section {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.estado section span {
    font-size: 0.9rem;
    font-weight: 500;
    padding-left: 0;
    color: red;
}

.estado i {
    width: 16px;
    height: 16px;
    background-color: red;
    border-radius: 50%;
    float: left;
    margin-right: 6px;
    transform: translateY(-1px);
}

.estado.activo i {
    background-color: var(--color-verde);
}

.estado.activo section span {
    color: var(--color-verde);
}

.estado.yellow i {
    background-color: darkorange;
}

.estado.yellow section span {
    color: darkorange;
}

/* ----------- Samuel - Luis ------------------ */
.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.profile-pic {
    width: 75%;
}

.sweet-loading {
    display: flex;
    width: 200px;
    margin: auto;
}

.sweet-loading>span {
    width: auto;
    margin: auto;
}

/* ---------------- msg-dialog -------------------- */

.msg-dialog {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #6666;
    z-index: 60000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg-dialog .msg-content {
    margin: 20px;
    padding: 20px;
    background-color: #eeee;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #333;
    /*min-width: 500px;*/
    
    display: grid;

}

.msg-content .btn {
    margin: 4px;
    float: inherit !important;
}

.msg-content h4 {
    margin: 4px;
    padding-bottom: 10px;
}

.msg-content .msg-buttons {
    border-top: 1px dotted #008c17;
    padding-top: 20px;
    text-align: center;
}

.msg-content td {
    padding: 4px;
}

.msg-content td:first-child {
    text-align: right;
}

.cyg-medios-pago {
    border: var(--color-ccc) 1px solid;
    border-radius: var(--radius-8);
    padding: 12px;
    margin: 12px 0;
    width: 100%;
    float: left;
}

.cyg-medios-pago figure {
    width: 80px;
    height: 80px;
}

.cyg-medios-pago section {
    width: calc(100% - 80px);
    padding-left: 16px;
}

.cyg-medios-pago p {
    font-size: 0.9rem;
    margin-bottom: 0 !important;
}

.cyg-medios-pago span {
    font-weight: 600;
}

.cyg-medios-pago button {
    font-size: 0.8rem !important;
}

/* VARIANTES */
.grid-variantes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.mod-variantes .cyg-col-full {
    padding: 0 !important;
}

/* .mod-variantes section div {
    background-color: #eee;
    padding: 15px;
} */

/* UPDATE JULIO 2023 */

/* buscador */
.ichef--buscador {
    position: relative;
    top: 0;
    bottom: 0;
    right: 10px;
    z-index: 900;
    width: 270px;
    height: 47px !important;
    margin: auto;
    transform: translateX(-43px);
    display: none !important;
}

.ichef--buscador form {
    display: flex;
    flex-direction: column;
}

.ichef--buscador form input {
    height: 30px !important;
    border: none !important;
    margin: 0;
}

.ichef--buscador form button.search {
    width: 30px;
    height: 30px;
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url('../images/svg/ico-lupa.svg');
    margin: auto;
    background-color: transparent;
    border: none !important;
}

.ichef--buscador .autocomplete-input .wrapper {
    align-items: flex-start !important;
}

/* visible */
.visible-buscador .ichef--buscador {
    display: flex !important;
}

/* .visible-buscador .menu {
    opacity: 0.2;
} */

/* flotante carpetas */

/* btn */
.btn--carpetas {
    width: 65px;
    height: 65px;
    position: fixed;
    top: var(--top-main-buscador);
    left: 40px;
    border-radius: 50%;
    background-color: var(--color-fff);
    border: 2px solid var(--color-verde);
    margin: auto;
    z-index: 999;
    box-shadow: 0 0 45px 0 rgb(0 0 0 / 35%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    /* animation    : filtro 3s infinite; */
}

/* ico */
.btn--carpetas::before {
    content: '';
    width: 35px;
    height: 35px;
    background-image: url('../images/ico-carpeta.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.btn--carpetas::after {
    content: '';
    background-image: url('../images/svg/ico-cerrar.svg');
    background-position: center;
    background-size: contain;
    opacity: 0;
    visibility: hidden;
    height: 0;
    width: 0;
    transition: all 0.3s ease;
}

/* visible */
.visible-carpetas .btn--carpetas {
    left: calc(var(--w-carpetas) + 15px);
    border: none;
    z-index: 991;
}

.visible-carpetas .btn--carpetas::before {
    display: none;
}

.visible-carpetas .btn--carpetas::after {
    opacity: 1;
    visibility: visible;
    width: 20px;
    height: 20px;
}

/* #carpetas */
#carpetas {
    width: var(--w-carpetas);
    height: 100vh;
    background-color: var(--color-fff);
    padding: 35px 30px 35px 35px;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 0 50px 50px 0 rgb(0 0 0 / 35%);
    transform: translateX(-500px);
    transition: all 0.3s ease;
    z-index: 0;
}

/* visible */
.visible-carpetas #carpetas {
    z-index: 998;
    transform: translateX(0);
}

.visible-carpetas #main {
    z-index: 900;
}

.visible-carpetas .sidebar-background::after {
    visibility: visible;
}

/* tit */
#carpetas h5 {
    color: var(--color-gris);
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 1rem;
    transform: translateX(-10px);
    cursor: pointer;
}

#carpetas h4 {
    font-weight: 600;
    color: var(--color-verde);
    margin: 30px 0 20px;
    font-size: 0.9rem;
    width: 100%;
    float: left;
}

#carpetas h3 {
    font-weight: 600;
    color: var(--color-gris);
    margin: 30px 0 20px;
    font-size: 0.9rem;
    width: 100%;
    float: left;
    cursor: pointer;
}

/* secciones */
#carpetas section {
    margin-bottom: 20px;
}

#carpetas .tit--nueva {
    display: flex;
    align-items: center;
}

#carpetas .tit--nueva::before {
    content: '';
    width: 40px;
    height: 33px;
    background-image: url('../images/ico-nueva-carpeta.png');
    background-position: center;
    background-size: contain;
    display: block;
    margin-right: 10px;
}

#carpetas .tit--nueva:hover {
    color: var(--color-verde);
}

/* carpetas */
.carpeta {
    width: 100%;
    float: left;
    background-color: #f2f6fc;
    padding: 5px 12px;
    display: grid;
    grid-template-columns: 1fr 28px;
    border-radius: 6px;
    margin-bottom: 5px;
    border: 1px solid #f2f6fc;
    cursor: pointer;
}

.carpeta>span,
.carpeta>a {
    font-weight: 600;
    font-size: 0.9rem;
    color: var(--color-gris);
    display: flex;
    align-items: center;
}

.carpeta>span::before,
.carpeta>a::before {
    content: '';
    width: 35px;
    height: 28px;
    background-image: url('../images/ico-carpeta-simple.png');
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.carpeta.public>span::before,
.carpeta.public>a::before,
.carpeta.fixed>span::before,
.carpeta.fixed>a::before {
    background-image: url('../images/ico-carpeta.png');
}

.carpeta.fav>span::before,
.carpeta.fav>a::before {
    background-image: url('../images/ico-carpeta-favoritos.png');
}

.carpeta.priv>span::before,
.carpeta.priv>a::before {
    background-image: url('../images/ico-carpeta-privadas.png');
}

.carpeta.fixed>i,
.carpeta.priv>i,
.carpeta.fav>i {
    width: 28px;
    height: 28px;
    background-image: none;
    background-position: right;
    background-size: 15px;
    background-repeat: no-repeat;
}

/* ico */
.carpeta .public i,
.carpeta i {
    width: 28px;
    height: 28px;
    background-image: url('../images/ico-carpeta-sub.svg');
    background-position: right;
    background-size: 15px;
    background-repeat: no-repeat;
}

/* sub carpetas */
.sub--carpeta {
    width: 160px;
    position: absolute;
    right: 0;
    top: -10px;
    padding: 10px 15px;
    background-color: #fff;
    border: 1px solid var(--color-verde);
    border-radius: var(--radius-8);
    z-index: 900;
    display: none;
    transform: translateX(150px);
}

.sub--carpeta a {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #000;
    margin-bottom: 5px;
}

.sub--carpeta a::before {
    content: '';
    width: 23px;
    height: 23px;
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
}

.sub--carpeta a.btn-compartir-carpeta::before {
    background-image: url('../images/ico-carpeta-compartir.png');
}

.sub--carpeta a.btn-edit-carpeta::before {
    background-image: url('../images/ico-carpeta-editar.png');
}

.sub--carpeta a.btn-eliminar-carpeta::before {
    background-image: url('../images/ico-carpeta-eliminar.png');
}

.sub--carpeta a:hover,
.sub--carpeta a.activo {
    text-decoration: underline;
}

/* hover - activo */
.carpeta:hover,
.carpeta.activo {
    border: 1px solid var(--color-verde);
}

/*
.carpeta:hover i,
.carpeta.activo i {
   background-image: url('../images/ico-carpeta-sub-verde.svg');
}
*/
.carpeta .sub--carpeta.activo {
    display: block;
}

/* lightbox */

/* .cyg-lightbox.carpetas {
    opacity: 1;
	display: flex;
	visibility: visible;
	z-index: 999999999;
} */

.cyg-lightbox.carpetas form {
    width: 300px;
    background-color: #fff;
    border: 1px solid var(--color-verde);
    padding: 30px;
    border-radius: var(--radius-8);
}

.modal {
    width: 620px;
    background-color: #fff;
    border: 1px solid var(--color-verde);
    padding: 30px;
    border-radius: var(--radius-8);
}

.cyg-lightbox.advertise .tit-advertise {
    color: var(--color-gris);
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.cyg-lightbox.advertise .tit-advertise::before {
    content: '';
    width: 40px;
    height: 33px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    margin-right: 10px;
}

/* tit */
.cyg-lightbox.carpetas .tit-carpeta {
    color: var(--color-gris);
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.cyg-lightbox.carpetas .tit-carpeta::before {
    content: '';
    width: 40px;
    height: 33px;
    background-image: url('../images/ico-nueva-carpeta.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    margin-right: 10px;
}



/* form */
.cyg-lightbox.carpetas form input {
    border: 0;
    border-bottom: 1px solid var(--color-verde);
    padding: 5px 0;
    border-radius: 0;
    height: auto;
}

.cyg-lightbox.carpetas form section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cyg-lightbox.carpetas form button {
    margin: auto;
}

/* visible */
.visible-form-carpeta .cyg-lightbox.carpetas#nueva-carpeta {
    display: flex;
    z-index: 999999999;
    visibility: visible;
    opacity: 1;
}

.visible-form-carpeta #main {
    z-index: 900;
}

.visible-advertise .cyg-lightbox.advertise#advertise {
    display: flex;
    z-index: 999999999;
    visibility: visible;
    opacity: 1;
}

.visible-advertise #main {
    z-index: 900;
}

.visible-asignar-carpetas .cyg-lightbox.carpetas#asignar-carpeta {
    display: flex;
    z-index: 999999999;
    visibility: visible;
    opacity: 1;
}

.visible-asignar-carpetas #main {
    z-index: 900;
}

.visible-compartir-carpeta .cyg-lightbox.carpetas#compartir-carpeta {
    display: flex;
    z-index: 999999999;
    visibility: visible;
    opacity: 1;
}

.visible-compartir-carpeta #main {
    z-index: 900;
}

.visible-eliminar-carpeta .cyg-lightbox.carpetas#eliminar-carpeta {
    display: flex;
    z-index: 999999999;
    visibility: visible;
    opacity: 1;
}

.visible-eliminar-carpeta #main {
    z-index: 900;
}

/* #asignar */

.cyg-lightbox.carpetas#asignar-carpeta h5 {
    color: var(--color-verde);
    font-weight: 600;
    font-size: 1rem;
}

.cyg-lightbox.carpetas#asignar-carpeta .nueva h5 {
    cursor: pointer;
}

.cyg-lightbox.carpetas#asignar-carpeta p {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.cyg-lightbox.carpetas#asignar-carpeta .sec--check {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block !important;
}

.cyg-lightbox.carpetas#asignar-carpeta label.check,
.cyg-lightbox.carpetas#asignar-carpeta .sec--check>div {
    display: flex;
    position: relative;
    margin: 0 0 10px;
    font-size: 0.9rem;
}

.cyg-lightbox.carpetas#asignar-carpeta label.check::before {
    content: '';
    width: 25px;
    height: 25px;
    background-image: url('../images/ico--check.png');
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.cyg-lightbox.carpetas#asignar-carpeta input[type='checkbox']:checked+label::before,
.cyg-lightbox.carpetas#asignar-carpeta input[type='checkbox'].activo+label::before {
    background-image: url('../images/ico--check-activo.png');
}

.cyg-lightbox.carpetas#asignar-carpeta label.check:hover {
    text-decoration: underline;
}

.cyg-lightbox.carpetas#asignar-carpeta button {
    margin-top: 15px;
}

.nueva {
    border-top: 1px solid var(--color-verde);
    padding-top: 15px;
    margin-top: 15px;
}

.nueva a {
    margin-bottom: 0 !important;
}

.nueva a:hover {
    color: var(--color-verde) !important;
}

/* visible */
.visible-asignar-carpeta .cyg-lightbox.carpetas#asignar-carpeta {
    display: flex;
    z-index: 999999999;
    visibility: visible;
    opacity: 1;
}

.visible-asignar-carpeta #main {
    z-index: 900;
}

.select-recipe {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
}

.activo .select-recipe {
    background-color: rgb(0 0 0 / 65%);
}

.select-recipe label.check {
    display: flex;
    position: relative;
    margin: 10px 0 0 18px;
    font-size: 0.9rem;
}

.select-recipe label.check::before {
    content: '';
    width: 25px;
    height: 25px;
    background-image: url('../images/ico--check-blanco.png');
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.select-recipe input[type='checkbox'].activo+label::before {
    background-image: url('../images/ico--check-blanco-activo.png');
}

.show-mobile {
    display: none;
}

.show-pc {
    display: block;
}

.select-container {
    padding-bottom: 5px;
}

.select-ingredient {
    background-color: var(--color-fff);
    border: 1px solid var(--color-verde);
    width: auto;
    border-radius: 8px;
    font-size: 0.9rem;
    cursor: pointer;
    margin-right: 2px;
    /*transition: all 0.3s ease;*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-units {
    padding: 10px 30px 10px 20px;
    color: var(--color-verde);
    text-align: center;
    font-size: 1rem;
    &:hover {
        background: var(--color-fff);
        color: var(--color-verde);
    }
}
